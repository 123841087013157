<template>
  <div v-if="!isLoading">
    <!-- BANNER -->
    <div class="section banner">
      <div id="carousel-banner" class="carousel slide" data-ride="carousel">
        <div class="carousel-inner">
          <div v-for="(item, index) in data.homeSlider" :key="index" class="carousel-item"
               :class="index===0 ? 'active': null">
            <img :alt="`banner-image-${index}`" :src="item.image_path" class="image-background image-fit">
            <div class="overlay-bg"></div>
            <div class="container">
              <div class="wrap-caption left">
                <h2 class="caption-heading">
                  {{ item.title }}
                </h2>
                <p class="excerpt">{{ item.description }}</p>
                <router-link class="btn btn-ghost-light" :to="{ name: 'contact'}">CONTACT US</router-link>
                <a v-if="item.more_url !== null" :href="item.more_url" class="btn btn-primary" title="LEARN MORE">LEARN MORE</a>
              </div>
            </div>
          </div>
        </div>
        <a class="carousel-control-prev" href="#carousel-banner" role="button" data-slide="prev">
          <i class="fa fa-chevron-left"></i>
        </a>
        <a class="carousel-control-next" href="#carousel-banner" role="button" data-slide="next">
          <i class="fa fa-chevron-right"></i>
        </a>
      </div>
    </div>

    <!-- SHORTCUT -->
    <div class="section feature bg-light">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-12">
            <h2 class="section-heading">
              WHY CHOOSE US
            </h2>
            <div class="row">
              <div v-for="(item, index) in data.whyChooseUs.articles" :key="index" class="col-sm-4 col-md-4">
                <div class="feature-box-8">
                  <div class="media">
                    <img :src="item.image_path" alt="rud" class="img-responsive img-full">
                  </div>
                  <div class="body">
                    <a href="" class="title">{{ item.title }}</a>
                    <p>{{ item.description }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <!-- ABOUT -->
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-12">
            <h2 class="section-heading">
              ABOUT US
            </h2>
            <div class="html-content" v-html="data.aboutUs.content"></div>
          </div>

        </div>
      </div>
    </div>

    <!-- CTA -->
    <div class="section cta bg-overlay-1">
      <div class="container">

        <div class="row">
          <div class="col-sm-12 col-md-12">
            <div class="cta-info">
              <i class="fa fa-leaf fa-3x"></i>
              <h3>{{ data.quotes.title }}</h3>
              <p>{{ data.quotes.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- STATS -->
    <div class="section statistic bg-overlay-2">
      <div class="container">
        <div class="row">
          <div class="col-sm-8 col-md-8">
            <h2 class="jumbolead">We Have Great Achievement To Show!!</h2>
          </div>
          <div class="col-sm-4 col-md-4">
            <div class="row">

              <div class="col-sm-6 col-md-6">
                <div class="counter-1">
                  <div class="counter-number">
                    {{data.stats.total_project}}
                  </div>
                  <div class="counter-title"><b>Projects</b></div>
                </div>
              </div>
              <div class="col-sm-6 col-md-6">
                <div class="counter-1">
                  <div class="counter-number">
                    {{data.stats.total_user}}
                  </div>
                  <div class="counter-title"><b>Users</b></div>
                </div>
              </div>
              <!--<div class="col-sm-6 col-md-6">
                <div class="counter-1">
                  <div class="counter-number">
                    10
                  </div>
                  <div class="counter-title">Years Experience</div>
                </div>
              </div>
              <div class="col-sm-6 col-md-6">
                <div class="counter-1">
                  <div class="counter-number">
                    232
                  </div>
                  <div class="counter-title">Persons Mentored</div>
                </div>
              </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- SERVICES -->
    <div class="section section-border">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-12">
            <h2 class="section-heading">
              CLIENTS
            </h2>
          </div>
          <div class="col-sm-12 col-md-12">
            <div class="row">
              <div v-for="(item, index) in data.clients" :key="index" class="col-sm-3 col-md-3">
                <div class="box-icon-3 box-full-width">
                  <a :href="item.url" target="_blank">
                    <img :src="item.image_path != null ? item.image_path : `https://via.placeholder.com/600x400`" alt=""
                         class="image-client">
                  </a>
                </div>
                <div class="spacer-30"></div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-12">
                <div class="spacer-50"></div>
                <div class="text-center">
                  <router-link class="btn btn-primary" :to="{ name: 'clients'}">ALL CLIENTS</router-link>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <!-- EVENTS -->
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-12">
            <h2 class="section-heading">
              UPCOMING EVENTS
            </h2>
          </div>
          <div class="col-sm-12 col-md-12">
            <div class="list-events">
              <div v-for="(item,index) in data.events" :key="index" class="box-event">
                <div class="meta-date">
                  <div class="month">{{ item.start_time | monthOnly }}</div>
                  <div class="date">{{ item.start_time | dateOnly }}</div>
                </div>
                <div class="body-content">
                  <h4><a href="">{{ item.name }}</a></h4>
                  <div class="meta">
                    <span class="location"><i class="fa fa-map-marker"></i> {{ item.location }}</span>
                    <span class="date"><i
                        class="fa fa-clock-o"></i>  {{ item.start_time | time12 }} - {{ item.end_time | time12 }}</span>
                  </div>
                </div>
<!--                <div class="detail-event">-->
<!--                  <a href="" class="btn btn-ghost-dark">VIEW DETAIL</a>-->
<!--                </div>-->
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-12">
                <div class="spacer-50"></div>
                <div class="text-center">
                  <router-link class="btn btn-primary" :to="{ name: 'events'}">ALL EVENTS</router-link>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <!-- STORIES -->
    <div class="section bg-light">
      <div class="container">
        <div id="carousel-stories" class="carousel slide" data-ride="carousel">
          <ol class="carousel-indicators">
            <li v-for="(item,index) in data.successStories.articles" :key="index" data-target="#carousel-stories"
                :data-slide-to="index"
                :class="index===1 ? 'active': null"></li>
          </ol>
          <div class="carousel-inner">
            <div v-for="(item, index) in data.successStories.articles" :key="index" class="item carousel-item"
                 :class="index===1 ? 'active': null">
              <div class="row">
                <div class="col-sm-5 col-md-5">
                  <h2 class="section-heading">
                    {{ item.category.name }}
                  </h2>
                  <h2 class="reset-section-heading">{{ item.title }}</h2>
                  <div v-html="item.content"/>
                  <div class="spacer-30"></div>
                  <router-link :to="{ name: item.slug}" class="nav-link">
                    <a class="btn btn-primary" href="">READ MORE STORIES</a>
                  </router-link>
                  <div class="spacer-30"></div>
                </div>
                <div class="col-sm-7 col-md-7">
                  <div class="about-img">
                    <div class="about-img-bottom">
                      <img :src="item.image_path? item.image_path : 'https://via.placeholder.com/600x400'" alt=""
                           class="img-responsive">
                    </div>
                  </div>
                </div>
                <div class="clearfix"></div>
                <div class="spacer-70"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--    <div class="section blog">-->
    <!--      <div class="container">-->
    <!--        <div class="row">-->

    <!--          <div class="col-sm-12 col-md-12">-->
    <!--            <h2 class="section-heading">-->
    <!--              RECENT NEWS-->
    <!--            </h2>-->
    <!--          </div>-->

    <!--          <div class="clearfix"></div>-->

    <!--          <div v-for="(item,index) in data.recentNews.articles" :key="index" class="col-sm-4 col-md-4">-->
    <!--            &lt;!&ndash; BOX 1 &ndash;&gt;-->
    <!--            <div class="box-news-1">-->
    <!--              <div class="media gbr">-->
    <!--                <a href="#">-->
    <!--                  <img :src="item.image_path != null ? item.image_path : `https://via.placeholder.com/600x400`" alt=""-->
    <!--                       @error="$event.target.src='https://via.placeholder.com/600x400'"-->
    <!--                       class="img-responsive img-full"-->
    <!--                       height="400"-->
    <!--                  >-->
    <!--                </a>-->
    <!--              </div>-->
    <!--              <div class="body">-->
    <!--                <div class="title">-->
    <!--                  <a href="" title=""> {{ item.description }} </a>-->
    <!--                </div>-->
    <!--                <div class="meta">-->
    <!--                  <span class="date"><i class="fa fa-clock-o"></i> {{ item.publish_date | date }}</span>-->
    <!--                  &lt;!&ndash;                  <span class="comments"><i class="fa fa-comment-o"></i> 0 Comments</span>&ndash;&gt;-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->

    <!--          <div class="clearfix"></div>-->

    <!--          <div class="col-sm-12 col-md-12">-->
    <!--            <div class="spacer-50"></div>-->
    <!--            <div class="text-center">-->
    <!--              <router-link class="btn btn-primary" :to="{ name: 'news'}">ALL NEWS</router-link>-->
    <!--            </div>-->
    <!--          </div>-->

    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!-- STORIES -->
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      isLoading: true,
      data: {
        homeSlider: null,
        whyChooseUs: null,
        successStories: null,
        recentNews: null,
        clients: null,
        events: null,
        quotes: null,
        aboutUs: null,
        stats: null,
      },
      setting: {
        banner: ['left', 'center', 'right']
      }
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.isLoading = true;
      this.$axios.get(`led-home`).then(res => {
        res.data.data;
        const response = res.data.data;
        this.data = {
          homeSlider: response['home-slider'],
          whyChooseUs: response['why-choose-us'],
          successStories: response['success-stories'],
          recentNews: response['recent-news'],
          clients: response['clients'],
          events: response['events'],
          quotes: response['quote'],
          aboutUs: response['about-us'],
          stats: response['stats'],
        };
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        this.isLoading = false;
      });
    }
  },
  computed: {
    bannerPosition() {
      console.log(Math.floor(Math.random() * this.setting.banner.length));
      return this.setting.banner[Math.floor(Math.random() * this.setting.banner.length)]
    }
  }
}
</script>
<style scoped>
.img-full {
  width: 100% !important;
}
</style>
